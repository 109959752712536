import flow from 'lodash/flow';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isElement from 'lodash/isElement';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';

import { FORMAT_TYPES } from '../../../components/Blog/PostList/constants';
import { LAYOUT_MASONRY } from '../../../components/ItemsView/constants';
import { PAGE_TYPE_BLOG } from '../../../components/Navigation/constants/pageTypes';
import { DEVICES } from '../../../device';
import { VIDEO_WIDGET_SELECTOR } from '../../constants';
import { getDeviceType } from '../../helpers/browser';
import getStateValue from '../../helpers/getStateValue';
import ieChangeCssVar from '../../helpers/ieChangeCssVar';
import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import dom from '../../wrapper/DomWrapper';
import StoreSort from '../Ecommerce/ecwid/custom/CatalogPage/Sort';
import { Parallax } from '../Parallax';
import Video from '../Video';

import { prepareItems as prepareMasonryItems } from './Masonry/utils';
import {
  actualProductPerRow, getHeaderHeight, getLimit, getModifier, removeVideoNode,
  toDom,
} from './utils';

export const DEVICE_SCROLL_OFFSET = {
  [DEVICES.PHONE]: 20,
  [DEVICES.TABLET]: 30,
  [DEVICES.LAPTOP]: 40,
  [DEVICES.DESKTOP]: 60,
  [DEVICES.QUAD]: 90,
};

export const MAX_RETRY_COUNT = 3;

class LayoutController {
  components = {};

  lastMeta = {};

  lastData = {};

  lastEvent = '';

  retryCount = 0;

  constructor(
    container,
    settings,
    itemRenderer,
    placeholderRenderer,
    spinnerRenderer,
    retryRenderer,
    pagination
  ) {
    this.container = container;
    this.settings = settings;
    this.itemRenderer = itemRenderer;
    this.placeholderRenderer = placeholderRenderer;
    this.spinnerRenderer = spinnerRenderer;
    this.retryRenderer = retryRenderer;
    this.pagination = pagination;

    const { layoutSettings } = settings;

    this.realGrid = get(layoutSettings, 'realGrid');
    this.isWidget = dom.hasClass(this.container, 'js-widget');
    this.isEcommerceCatalogWidget = this.settings?.type === 'ecommerceCatalog' && this.isWidget;
  }

  saveMeta = (meta) => {
    this.lastMeta = meta;

    return meta;
  };

  setLoading = (isLoading, event) => {
    this.isLoading = isLoading;
    const {
      noLoadingState,
      spinnerPerItem,
      spinnerOnce,
      spinnerClassName,
    } = this.settings;

    if (noLoadingState) return;

    const elItemsContainer = this.getItemsContainer();

    if (!elItemsContainer) return;

    if (!spinnerPerItem) {
      if (isLoading) {
        const minHeight = (event === 'change' && this.isEcommerceCatalogWidget) ? `${dom.getElementHeight(elItemsContainer)}px` : '';

        if (minHeight) {
          dom.updateStyle(elItemsContainer, {
            minHeight,
          });
        }

        if (spinnerClassName) dom.addClass(this.container, spinnerClassName);
      } else {
        dom.updateStyle(elItemsContainer, {
          minHeight: '0px',
        });

        if (spinnerClassName) dom.removeClass(this.container, spinnerClassName);

        return;
      }
      if (spinnerOnce && !['init', 'retry'].includes(event)) return;

      const spinner = flow(
        this.spinnerRenderer.render,
        toDom
      )();

      if (event !== 'change') {
        dom.updateStyle(
          elItemsContainer,
          { minHeight: `${Math.round(dom.window.innerHeight / 2)}px` }
        );
      }

      elItemsContainer.innerHTML = '';
      elItemsContainer.append(spinner);
    }
  };

  init = async () => {
    const { type } = this.settings;
    const preInit = getModifier(type, 'preInit');

    if (preInit) {
      const components = await preInit();

      this.components = { ...this.components, ...components };
    }

    this.pagination.on(['init', 'change'], async (data, event) => {
      this.lastData = data;
      this.lastEvent = event;
      await this.runAction(data, event);
    });

    this.pagination.init();
    subscribeToDeviceChanging(this, this.calculateGrid);

    this.initStoreSortWidget();
  };

  initStoreSortWidget = () => {
    const elStoreSortWidget = dom.getElement('[data-widget="storeSort"]', this.container);

    if (!elStoreSortWidget) return;

    const { category, sortBy } = this.getRequestParams();

    // eslint-disable-next-line no-new
    new StoreSort(elStoreSortWidget, {
      category,
      sortBy,
      components: this.components,
      resetPagination: this.resetPagination,
      updateFetchParams: (params, isDefaultCategory) => {
        if (!isPlainObject(params)) return;

        const newRequestParams = {
          ...this.getRequestParams(),
          ...params,
        };

        if (isDefaultCategory) {
          delete newRequestParams.category;
        }

        this.settings.requestParams = newRequestParams;
      },
    });
  };

  getRequestParams = () => {
    const { requestParams } = this.settings;

    return isPlainObject(requestParams) ? requestParams : {};
  };

  runAction = async (data = this.lastData, event = this.lastEvent) => {
    const {
      emptyListClassName,
      itemSize,
      layoutSettings: {
        layoutType,
      },
      layoutSettings,
    } = this.settings;

    this.setLoading(true, event);

    if (this.fetchError) {
      const realLimit = getLimit(
        itemSize,
        {
          layoutType,
          ...layoutSettings[layoutType],
        },
        getDeviceType()
      );

      if (realLimit !== data.limit) {
        // eslint-disable-next-line no-param-reassign
        data.limit = realLimit;
        // eslint-disable-next-line no-param-reassign
        data.offset = 0;
      }
    }

    dom.removeClass(this.container, emptyListClassName);
    dom.removeClass(this.container, '_show');
    this.response = await this.fetchItems(data);
    if (!this.response) return;

    flow(
      this.saveMeta,
      this.drawItems,
      (dataOb) => {
        this.pagination.setState('isLoading', false);
        this.resizeItems(getDeviceType());

        return dataOb;
      },
      this.drawPager,
      () => {
        this.setLoading(false, event);
        Parallax.forceUpdate();
        if (event === 'change' && !this.isEcommerceCatalogWidget) this.scrollTop();
      }
    )(this.response);
  };

  redraw = (limit) => {
    const { offset } = this.lastMeta;
    const length = get(this, 'response.items.length');
    const device = getDeviceType();

    flow(
      this.drawItems,
      this.drawPager,
      () => this.resizeItems(device)
    )(this.response);

    if (length >= limit && !offset) {
      return;
    }

    this.pagination.setPage(0);
  };

  calculateGrid = (device) => {
    const {
      layoutSettings: {
        layoutType,
      },
      layoutSettings,
      itemSize,
    } = this.settings;

    if (this.realGrid) {
      const limit = getLimit(itemSize, {
        layoutType,
        ...layoutSettings[layoutType],
      }, getDeviceType());

      if (limit === this.pagination.state.limit) return;

      this
        .pagination
        .setState('limit', limit);
      if (this.fetchError) {
        this.drawRetry();
      } else {
        this.redraw(limit);
      }

      return;
    }

    this.resizeItems(device);
  };

  resizeItems = (device) => {
    const {
      layoutSettings: {
        grid: { items_per_row: items } = {},
        layoutType,
      },
      itemSize,
      itemClassName,
    } = this.settings;

    if (layoutType !== 'grid' || !items) return;

    const elItemsContainer = this.getItemsContainer();

    if (!elItemsContainer) return;

    // eslint-disable-next-line camelcase
    const newItemsPerRow = actualProductPerRow(device, itemSize, { items_per_row: items });

    [...elItemsContainer.classList].forEach((className) => {
      const matches = className.match(/^(.*)-per-row_(\d+)$/);

      if (!matches) return;

      if (Number.parseInt(matches[2], 10) === newItemsPerRow) return;

      dom.removeClass(elItemsContainer, className);
      dom.addClass(elItemsContainer, `${matches[1]}-per-row_${newItemsPerRow}`);
      dom.updateStyle(elItemsContainer, {
        gridTemplateColumns: `repeat(${Math.ceil(newItemsPerRow)}, 1fr)`,
      });
    });

    dom.getCollection(`.${itemClassName}`, elItemsContainer).forEach((node) => {
      dom.updateStyle(node, { width: `${100 / newItemsPerRow}%` });
    });

    ieChangeCssVar();
  };

  getItems = ({ limit = 10, offset = 0 }) => {
    const { source, requestParams = {}, type } = this.settings;
    const timestamp = Date.now();
    const isTemplate = getStateValue('isTemplate', false);

    const params = isObject(requestParams)
      ? {
        ...requestParams, limit, offset, timestamp,
      }
      : { limit, offset, timestamp };

    if (isString(source)) {
      const paramsString = Object
        .keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

      return dom.window
        .fetch(`${source}${source.includes('?') ? '&' : '?'}${paramsString}`)
        .then((response) => response.json());
    }

    if (isObject(source) && type === 'blog') {
      const blogTag = getStateValue('blogTag', null);
      let url = source.blog;

      if (blogTag) {
        url = `${source.tag}/${blogTag.slug}/post`;
      }

      if (isTemplate) {
        const projectId = getStateValue('projectId');

        url = `${url}?templateLink=${projectId}`;
      }

      const paramsString = Object
        .keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

      return dom.window
        .fetch(`${url}${url.includes('?') ? '&' : '?'}${paramsString}`)
        .then((response) => response.json());
    }

    const getter = get(this, ['components', ...source]);

    if (!getter) {
      throw new Error('Wrong configuration');
    }

    return getter(params);
  };

  fetchItems = (meta) => {
    this.fetchError = false;

    return this.getItems(meta)
      .then((response) => {
        this.retryFetchProduct(false);

        this.response = response;

        return this.response;
      })
      .catch((error) => {
        this.retryFetchProduct(true,
          this.fetchItems.bind(this, meta),
          this.onFetchError.bind(this, error));
      });
  };

  onFetchError = (e) => {
    this.setLoading(false);
    const {
      withRetry,
    } = this.settings;

    if (!withRetry) return;

    this.fetchError = true;

    this.drawRetry();
    // eslint-disable-next-line no-console
    console.error(e);
  };

  retryFetchProduct = (
    isShouldRetry,
    fetchCb,
    errorCb = () => null
  ) => {
    if (!isShouldRetry) {
      this.retryCount = 0;
    } else {
      this.retryCount += 1;

      if (!fetchCb || this.retryCount >= MAX_RETRY_COUNT) {
        this.retryCount = 0;
        errorCb();
      } else {
        fetchCb();
      }
    }
  };

  getElementWidth = () => {
    const device = getDeviceType();
    const {
      layoutSettings: {
        grid: { items_per_row: items } = {},
        layoutType,
      },
      itemSize,
    } = this.settings;

    const elItemsContainer = this.getItemsContainer();

    if (!elItemsContainer) return 0;

    if (layoutType !== 'grid' || !items) return dom.getElementWidth(elItemsContainer);

    // eslint-disable-next-line camelcase
    const itemsPerRow = actualProductPerRow(device, itemSize, { items_per_row: items });

    return dom.getElementWidth(elItemsContainer) / itemsPerRow;
  };

  drawRetry = () => {
    const {
      type,
      itemClassName,
      emptyListClassName,
      itemSize,
      layoutSettings,
      layoutSettings: {
        layoutType,
      },
    } = this.settings;

    const elItemsContainer = this.getItemsContainer();

    if (!elItemsContainer) return;

    const device = getDeviceType();
    const limit = getLimit(itemSize, {
      layoutType,
      ...layoutSettings[layoutType],
    }, device);
    const retryData = {
      items: Array.from({ length: Math.min(limit, 5) }).fill(1),
      itemClassName,
    };

    const retry = flow(
      this.retryRenderer.render,
      toDom
    )(retryData);

    dom.removeElement(this.pagination.pager);

    dom.addHtml(elItemsContainer, retry.innerHTML);
    dom.on(dom.getElement('._try-btn', this.container), 'click', async () => {
      dom.addHtml(elItemsContainer, '');
      this.runAction(this.lastData, 'retry');
    });
    this.resizeItems(device);

    dom.addClass(this.container, emptyListClassName);
    dom.addClass(this.container, '_show');

    const onDidMount = getModifier(type, 'didMount');

    onDidMount.call(this, elItemsContainer);
  };

  drawPager = (data) => {
    if (!this.fetchError) {
      this.pagination.render(data);
    }

    return data;
  };

  getItemsContainer = () => {
    const { itemsContainerId } = this.settings;

    return dom.getElement(`#${itemsContainerId}`);
  };

  drawItems = (data) => {
    if (!isPlainObject(data)) return;

    const { items, limit: defaultLimit } = data;
    const {
      type,
      source,
      paginationType,
      emptyListClassName,
      layoutSettings: {
        reaLayoutType,
        layoutType,
      },
    } = this.settings;
    const elItemsContainer = this.getItemsContainer();

    if (!elItemsContainer || !items) return;

    if (paginationType !== 'infinite') dom.addHtml(elItemsContainer, '');
    if (items.length === 0 && !dom.hasClass(this.container, emptyListClassName)) {
      dom.addClass(this.container, emptyListClassName);
    }

    const limit = this.realGrid ? this.pagination.state.limit : defaultLimit;
    const isMasonry = layoutType === LAYOUT_MASONRY;
    const renderedItems = [];

    items.slice(0, limit).forEach((item, index) => {
      const postRender = getModifier(type, 'postRender');
      const render = flow(
        (dataOb) => getModifier(type, 'preRender').call(this, dataOb, index, elItemsContainer, source),
        this.itemRenderer.render,
        toDom,
        removeVideoNode(type, reaLayoutType),
        (element) => postRender.call(this, element, item, index),
        // eslint-disable-next-line unicorn/prefer-dom-node-append
        (element) => (isMasonry ? element : elItemsContainer.appendChild(element))
      );

      renderedItems.push(render(item));
    });

    if (isMasonry) this.drawMasonryItems(renderedItems, elItemsContainer);

    if (type === PAGE_TYPE_BLOG && reaLayoutType === FORMAT_TYPES.FEED) {
      const videos = new Video(VIDEO_WIDGET_SELECTOR);

      const containerNodes = document.querySelectorAll('.video-container');

      for (const containerNode of containerNodes) {
        containerNode.setAttribute('style', 'white-space: normal;');
      }

      const previewNodes = document.querySelectorAll('.video__preview');

      for (const previewNode of previewNodes) {
        const srcAttr = previewNode.getAttribute('src');

        if (!srcAttr) {
          const srcDataAttr = previewNode.dataset.src;

          previewNode.setAttribute('src', srcDataAttr);
        }
      }

      videos.init({ isPostList: true });
    }

    const onDidMount = getModifier(type, 'didMount');

    onDidMount.call(this, elItemsContainer);

    // eslint-disable-next-line consistent-return
    return data;
  };

  drawMasonryItems = (renderedItems, elItemsContainer) => {
    const device = getDeviceType();
    const {
      spacing,
      [LAYOUT_MASONRY]: {
        // eslint-disable-next-line camelcase
        total_rows,
        rows,
      } = {},
    } = this.settings.layoutSettings || {};
    const masonryRows = prepareMasonryItems(renderedItems, {
      device,
      // eslint-disable-next-line camelcase
      totalRow: total_rows,
      rows,
    });

    if (!isArray(masonryRows)) return;

    const elWrap = dom.createElement('div', { className: 'gallery-wrap' });

    masonryRows.forEach((row) => {
      if (!isArray(row)) return;

      const elRow = dom.createElement('div', { className: 'gallery-wrap__row' });

      row.forEach((elItem) => {
        if (!isElement(elItem)) {
          return;
        }

        elRow.append(elItem);
      });

      if (spacing) {
        // eslint-disable-next-line no-param-reassign
        elWrap.style.gap = `${spacing}px`;
        // eslint-disable-next-line no-param-reassign
        elRow.style.gap = `${spacing}px`;
      }

      elWrap.append(elRow);
    });

    elItemsContainer.append(elWrap);
  };

  scrollTop = () => {
    if (this.settings.paginationType === 'infinite') return;

    const scrollTop = this.container.getBoundingClientRect().top
      - DEVICE_SCROLL_OFFSET[getDeviceType()]
      - getHeaderHeight();
    const doc = dom.document.documentElement;
    const top = (dom.window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (top >= scrollTop) dom.window.scrollTo(0, scrollTop);
  };

  resetPagination = () => {
    if (!isFunction(this.pagination.setPage)) return;

    this.pagination.setPage(0);
  };
}

export default LayoutController;
