import checkImageResizable from '../../../../helpers/checkImageResizable';
import { BLOG_STATIC_DIR } from '../../../../helpers/getBlogImagesResizeUrl/constants';
import { convertSrcToCorrect } from '../../../helper/urls';
import getStateValue from '../getStateValue';

const IMAGE_SIZE = {
  width: 1200,
  height: 630,
};

/**
 *
 * @param {Object=} image
 * @param {boolean=} isStaticImagesPublished
 * @param {boolean=} isOriginalImage
 * @returns {string|null}
 */
const getImageUrl = ({ image, isStaticImagesPublished, isOriginalImage }) => {
  const apiUrls = getStateValue('apiUrls');
  const { imageResizeApiService } = apiUrls;
  const { url, imageUrl } = image || {};
  const preparedUrl = url || imageUrl;

  if (isOriginalImage) return preparedUrl;

  const isImageResizable = checkImageResizable(preparedUrl);

  if (!isImageResizable) return null;

  const originalSrc = convertSrcToCorrect(preparedUrl, apiUrls);
  const staticSrc = convertSrcToCorrect(preparedUrl, apiUrls, {
    isStaticPublished: true,
  });
  const pureStaticSrc = staticSrc.startsWith('/') ? staticSrc.slice(1) : staticSrc;

  return isStaticImagesPublished
    ? `https://${window.location.host}/${BLOG_STATIC_DIR}/ogimage-${pureStaticSrc}`
    : `${imageResizeApiService}?url=${originalSrc}&width=${IMAGE_SIZE.width}`;
};

export default getImageUrl;
