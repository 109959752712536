import getBlogImagesResize from '../../../../../helpers/getBlogImagesResize/getBlogImagesResize';
import { BG_DEVICE_SIZES } from '../../../../components/Section/constants';
import { convertSrcToCorrect } from '../../../../helper/urls';
import { toDate } from '../../../../helper/utils';
import getStateValue from '../../../helpers/getStateValue';
import dom from '../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../wrapper/WidgetWrapper';
import { getPictureSource } from '../utils';

const { BLOG_STATIC_DIR } = require('../../../../../helpers/getBlogImagesResizeUrl/constants');

const metaTemplate = (dateAdded, author) => `<div class="post-page__meta">
              <h6>
                <span itemProp="datePublished">
                  ${toDate(dateAdded, 'dd MMM yyyy')}
                </span>
                ${author ? `<span class="post-page__meta-divider">|</span><span itemProp="author">${author}</span>` : ''}
              </h6>
            </div>`;

/**
 *
 * @param {string} bannerSrc
 * @param {Array} images
 * @param {Object} options
 * @param {Object} options.layoutSettings
 * @returns {{source: string, url: string}|null}
 */
const getPictureData = (bannerSrc, images = [], { layoutSettings, isStaticImagesPublished }) => {
  if (!bannerSrc) return null;

  const previewImage = images.find(({ isPreview, isCustomPreviewImage }) => !!isPreview && !!isCustomPreviewImage);

  try {
    const apiUrls = getStateValue('apiUrls');
    const staticData = {
      isStaticPublished: isStaticImagesPublished,
      staticDir: `/${BLOG_STATIC_DIR}`,
    };
    const originalSrc = convertSrcToCorrect(bannerSrc, apiUrls);
    const [imageResize] = getBlogImagesResize(layoutSettings, [previewImage || {}]);
    const isValidImage = !!imageResize?.hash;
    const convertedSrc = isValidImage ? convertSrcToCorrect(bannerSrc, apiUrls, staticData) : originalSrc;

    const { heroTransform: transform, ...rest } = imageResize || {};
    const heroImage = {
      ...rest,
      transform,
      isHero: true,
    };

    return {
      url: convertedSrc,
      source: getPictureSource(convertedSrc, heroImage, BG_DEVICE_SIZES, {
        apiUrls,
        staticData,
        originalSrc,
        isValidImage,
      }),
    };
  } catch (error) {
    console.error(error);
  }
};

class Hero extends WidgetWrapper {
  getContent = (data, {
    layoutSettings,
    isStaticImagesPublished,
  }) => {
    const {
      title,
      dateAdded,
      author,
      bannerSrc,
      bannerHeight,
      meta,
      metaStyle,
      align,
      format,
      isStandardFormat,
      images,
    } = data;

    const isHero = format === 'hero';
    const alignValue = metaStyle !== 'compact' ? align : 'left';
    const heroBannerHeight = bannerHeight ? `post-page__${bannerHeight}` : '';
    const heroDomNode = dom.getElement(this.selector);
    const classOfFormat = `post-page__${format}`;
    const classHeroBannerHeight = isHero ? heroDomNode.classList.add(heroBannerHeight) : '';
    const classGalleryWrapContainer = !isStandardFormat ? 'gallery-wrap-container' : '';
    const picture = isHero ? getPictureData(bannerSrc, images, {
      layoutSettings,
      isStaticImagesPublished,
    }) : null;
    const metaContent = meta ? metaTemplate(dateAdded, author) : '';

    return {
      classOfFormat,
      classHeroBannerHeight,
      classGalleryWrapContainer,
      metaStyle,
      alignValue,
      meta: metaContent,
      title,
      picture,
      withPicture: !!picture?.url && !!picture?.source,
    };
  };

  beforeInitLazy = (parentPostDomNode) => {
    const elHero = dom.getElement('.post-page__picture > picture', parentPostDomNode);

    if (!elHero) return;

    dom.addClass(elHero, 'lazy');
  };
}

export default Hero;
