import isString from 'lodash/isString';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';

/**
 *
 * @param {string|JSON} excerpt
 * @returns {String}
 */
const getPlainExcerpt = (excerpt) => {
  if (!isString(excerpt)) return '';

  try {
    const excerptData = JSON.parse(excerpt);
    const excerptValue = Value.fromJSON(excerptData);

    return Plain.serialize(excerptValue);
  } catch {
    return excerpt;
  }
};

export default getPlainExcerpt;
